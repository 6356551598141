import { createContext, useContext } from "react";
import { SideNavContextInterface, SideNavProviderProps } from "@typings";

const initialState: SideNavContextInterface = {
  theme: "dark",
  isOpen: false,
};

const SideNavContext = createContext<SideNavContextInterface>(initialState);

export function SideNavProvider(props: SideNavProviderProps) {
  const { theme = initialState.theme, isOpen, children } = props;

  const store = {
    theme,
    isOpen,
  };

  return <SideNavContext.Provider value={store}>{children}</SideNavContext.Provider>;
}

export function useSideNavContext() {
  return useContext(SideNavContext);
}
