import React from "react";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

const ChampionTooltip = (props) => {
  const { useChampionIndiv, getIndividualChampion } = getRiotAssetsContext();
  const { championId, language } = props;
  const {
    data: champion,
    loading: fetchingChampion,
    error: errorChampion,
  } = useChampionIndiv(championId, { language, skip: true });

  const championJSON = champion || getIndividualChampion(championId, { language });

  if (!championJSON) {
    return null;
  }

  const { name } = championJSON;

  return (
    <div className="tooltip-champion-skill">
      <div className="name" style={{ textAlign: "center" }}>
        {name}
      </div>
    </div>
  );
};

export default ChampionTooltip;
