import classnames from "classnames";
import type { Tags } from "@typings";

interface TagInfo {
  label: React.ReactNode;
  miniLabel?: React.ReactNode;
}

const tagObject: Record<Tags, TagInfo> = {
  new: {
    label: "NEW",
    miniLabel: "N",
  },
  beta: {
    label: "BETA",
    miniLabel: "B",
  },
  soon: {
    label: (
      <span>
        SOON<sup>TM</sup>
      </span>
    ),
  },
};

export default function Tag(props: { tag: Tags; mini?: boolean }) {
  const { label, miniLabel } = tagObject[props.tag];

  if (props.mini && !miniLabel) {
    return null;
  }

  return (
    <div className={classnames(`tag ${props.tag}`, { tag_mini: props.mini })}>
      <span className="label">{props.mini ? miniLabel : label}</span>
    </div>
  );
}
