import classNames from "classnames";
import { SideNavProvider } from "./SideNavContext";

import type { SideNavProps } from "@typings";

export default function SideNav(props: SideNavProps) {
  const { theme, isOpen, className, children, ...otherProps } = props;

  const sideNavLinkClassNames = classNames(
    `side-nav_theme__${theme}`,
    {
      "side-nav_collapsed": !isOpen,
      "side-nav_expanded": isOpen,
    },
    className,
  );

  return (
    <SideNavProvider theme={theme} isOpen={isOpen}>
      <div id="side-nav-container" className={sideNavLinkClassNames} {...otherProps}>
        <div className="side-nav">{children}</div>
      </div>
    </SideNavProvider>
  );
}
