import { useState } from "react";
import classnames from "classnames";
import type { SUPPORTED_GAMES } from "@typings";
import { ReactComponent as LolLogo } from "@shared/assets/games/lol.svg";
import { ReactComponent as LolGreyLogo } from "@shared/assets/games/lol-grey.svg";
import { ReactComponent as ValorantLogo } from "@shared/assets/games/valorant.svg";
import { ReactComponent as ValorantGreyLogo } from "@shared/assets/games/valorant-grey.svg";
import { ReactComponent as WowLogo } from "@shared/assets/games/wow.svg";
import { ReactComponent as WowGreyLogo } from "@shared/assets/games/wow-grey.svg";
import { ReactComponent as TftLogo } from "@shared/assets/games/tft.svg";
import { ReactComponent as TftGreyLogo } from "@shared/assets/games/tft-grey.svg";

import type { GameNavButtonProps } from "@typings";

export function GameNavigator(props: { className?: string; children: React.ReactNode }) {
  return (
    <div className={classnames("side-nav_game-navigator", props.className)}>
      <div className="game-selector">{props.children}</div>
    </div>
  );
}

export function GameNavButton(props: GameNavButtonProps) {
  const { className, game, active, onClick } = props;
  const [hovered, setHovered] = useState(false);

  const { iconActive, iconInActive } = Games[game];

  const gameNavClassName = classnames(`game game_${game}`, className, {
    game_active: active,
  });

  return (
    <div
      className={gameNavClassName}
      onClick={() => onClick && onClick()}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered || active ? iconActive : iconInActive}
    </div>
  );
}

type Game = {
  iconActive: React.ReactNode;
  iconInActive: React.ReactNode;
  iconDisabled?: React.ReactNode;
};
export const Games: Record<SUPPORTED_GAMES, Game> = {
  lol: {
    iconActive: <LolLogo />,
    iconInActive: <LolGreyLogo />,
  },
  valorant: {
    iconActive: <ValorantLogo />,
    iconInActive: <ValorantGreyLogo />,
  },
  wow: {
    iconActive: <WowLogo />,
    iconInActive: <WowGreyLogo />,
  },
  tft: {
    iconActive: <TftLogo />,
    iconInActive: <TftGreyLogo />,
  },
};
