import classnames from "classnames";
import type { DividerProps } from "@typings";

export function Divider(props: DividerProps) {
  return (
    <div
      className={classnames(
        "side-nav_divider-container",
        {
          "margin-top": props.marginTop,
          "margin-bottom": props.marginBottom,
        },
        props.className,
      )}
    >
      <div className="side-nav_divider" />
    </div>
  );
}
