import { RoleS, getRoleSVG } from "@ugg/shared/utils/role-helpers";
import { getS13RankUrl, getRegionUrl, getLeagueSeriesUrl } from "@outplayed/riot-assets";

// ------------------------------------
// COMMON OPTIONS
// ------------------------------------

export const ROLE_OPTIONS = [
  { value: RoleS.TOP, label: "Top", img: getRoleSVG(RoleS.TOP), imgActive: getRoleSVG(RoleS.TOP, { active: true }) },
  { value: RoleS.JUNGLE, label: "Jungle", img: getRoleSVG(RoleS.JUNGLE), imgActive: getRoleSVG(RoleS.JUNGLE, { active: true }) },
  { value: RoleS.MIDDLE, label: "Middle", img: getRoleSVG(RoleS.MIDDLE), imgActive: getRoleSVG(RoleS.MIDDLE, { active: true }) },
  { value: RoleS.BOTTOM, label: "Bot", img: getRoleSVG(RoleS.BOTTOM), imgActive: getRoleSVG(RoleS.BOTTOM, { active: true }) },
  {
    value: RoleS.SUPPORT,
    label: "Support",
    img: getRoleSVG(RoleS.SUPPORT),
    imgActive: getRoleSVG(RoleS.SUPPORT, { active: true }),
  },
];

export const ALL_ROLE_OPTIONS = [
  { value: RoleS.ALL, label: "All Roles", img: getRoleSVG(RoleS.ALL) },
  { value: RoleS.TOP, label: "Top", img: getRoleSVG(RoleS.TOP), imgActive: getRoleSVG(RoleS.TOP, { active: true }) },
  { value: RoleS.JUNGLE, label: "Jungle", img: getRoleSVG(RoleS.JUNGLE), imgActive: getRoleSVG(RoleS.JUNGLE, { active: true }) },
  { value: RoleS.MIDDLE, label: "Middle", img: getRoleSVG(RoleS.MIDDLE), imgActive: getRoleSVG(RoleS.MIDDLE, { active: true }) },
  { value: RoleS.BOTTOM, label: "Bot", img: getRoleSVG(RoleS.BOTTOM), imgActive: getRoleSVG(RoleS.BOTTOM, { active: true }) },
  {
    value: RoleS.SUPPORT,
    label: "Support",
    img: getRoleSVG(RoleS.SUPPORT),
    imgActive: getRoleSVG(RoleS.SUPPORT, { active: true }),
  },
];

export const NEXUS_BLITZ_ROLE_OPTIONS = [
  { value: RoleS.JUNGLE, label: "Jungle", img: getRoleSVG(RoleS.JUNGLE), imgActive: getRoleSVG(RoleS.JUNGLE, { active: true }) },
  { value: RoleS.LANE, label: "Lane", img: getRoleSVG(RoleS.LANE), imgActive: getRoleSVG(RoleS.LANE, { active: true }) },
];

export const ALL_NEXUS_BLITZ_ROLE_OPTIONS = [
  { value: RoleS.ALL, label: "All Roles", img: getRoleSVG(RoleS.ALL) },
  ...NEXUS_BLITZ_ROLE_OPTIONS,
];

export const QUEUE_TYPE_OPTIONS = [
  { value: "ranked_solo_5x5", label: "Ranked Solo" },
  { value: "normal_aram", label: "ARAM" },
  { value: "ranked_flex_sr", label: "Ranked Flex" },
  { value: "normal_blind_5x5", label: "Normal Blind" },
  { value: "normal_draft_5x5", label: "Normal Draft" },
  { value: "nexus_blitz", label: "Nexus Blitz" },
  { value: "pick_urf", label: "URF" },
  // { value: "arurf", label: "ARURF" },
  // { value: "one_for_all", label: "One for All" },
];

export const RANK_OPTIONS_WITH_D2 = [
  { value: "platinum_plus", label: "Platinum +", img: getS13RankUrl("platinum_plus", true) },
  { value: "emerald_plus", label: "Emerald +", img: getS13RankUrl("emerald_plus", true) },
  { value: "diamond_plus", label: "Diamond +", img: getS13RankUrl("diamond_plus", true) },
  { value: "diamond_2_plus", label: "Diamond 2 +", img: getS13RankUrl("diamond_2_plus", true) },
  { value: "master_plus", label: "Master +", img: getS13RankUrl("master_plus", true) },
  { value: "overall", label: "All Ranks", img: getS13RankUrl("all_ranks", true) },
  { value: "challenger", label: "Challenger", img: getS13RankUrl("challenger", true) },
  { value: "grandmaster", label: "Grandmaster", img: getS13RankUrl("grandmaster", true) },
  { value: "master", label: "Master", img: getS13RankUrl("master", true) },
  { value: "diamond", label: "Diamond", img: getS13RankUrl("diamond", true) },
  { value: "emerald", label: "Emerald", img: getS13RankUrl("emerald", true) },
  { value: "platinum", label: "Platinum", img: getS13RankUrl("platinum", true) },
  { value: "gold", label: "Gold", img: getS13RankUrl("gold", true) },
  { value: "silver", label: "Silver", img: getS13RankUrl("silver", true) },
  { value: "bronze", label: "Bronze", img: getS13RankUrl("bronze", true) },
  { value: "iron", label: "Iron", img: getS13RankUrl("iron", true) },
];

export const REGION_OPTIONS = [
  { value: "world", label: "World", img: getRegionUrl("world") },
  { value: "na1", label: "NA", img: getRegionUrl("NA") },
  { value: "euw1", label: "EUW", img: getRegionUrl("EUW") },
  { value: "kr", label: "KR", img: getRegionUrl("flags/kr"), flag: true },
  { value: "br1", label: "BR", img: getRegionUrl("flags/br"), flag: true },
  { value: "eun1", label: "EUN", img: getRegionUrl("EUN") },
  { value: "jp1", label: "JP", img: getRegionUrl("flags/jp"), flag: true },
  { value: "la1", label: "LAN", img: getRegionUrl("LAN") },
  { value: "la2", label: "LAS", img: getRegionUrl("LAS") },
  { value: "oc1", label: "OCE", img: getRegionUrl("OCE") },
  { value: "ru", label: "RU", img: getRegionUrl("flags/ru"), flag: true },
  { value: "tr1", label: "TR", img: getRegionUrl("flags/tr"), flag: true },
  { value: "sg2", label: "SEA", img: getRegionUrl("SEA") },
  { value: "tw2", label: "TW", img: getRegionUrl("TW") },
  { value: "vn2", label: "VN", img: getRegionUrl("VN") },
  { value: "me1", label: "ME", img: getRegionUrl("ME") },
];

export const LEAGUE_SERIES_OPTIONS = [
  { value: "world", label: "World", img: getLeagueSeriesUrl("world-white") },
  { value: "lck", label: "KR", img: getLeagueSeriesUrl("LCK-white") },
  { value: "lpl", label: "CN", img: getLeagueSeriesUrl("LPL") },
  { value: "lec", label: "EU", img: getLeagueSeriesUrl("LEC-white") },
  { value: "lcs", label: "NA", img: getLeagueSeriesUrl("LCS-white") },
  { value: "lms", label: "TW", img: getLeagueSeriesUrl("LMS") },
  { value: "cblol", label: "BR", img: getLeagueSeriesUrl("CBLOL") },
  { value: "lcl", label: "CIS", img: getLeagueSeriesUrl("LCL") },
  { value: "lla", label: "LAT", img: getLeagueSeriesUrl("LLA") },
  { value: "lst", label: "SEA", img: getLeagueSeriesUrl("LST-white") },
  { value: "opl", label: "OCE", img: getLeagueSeriesUrl("OPL") },
  { value: "tcl", label: "TR", img: getLeagueSeriesUrl("TCL") },
  { value: "vcs", label: "VN", img: getLeagueSeriesUrl("VCS") },
  { value: "ljl", label: "JP", img: getLeagueSeriesUrl("LJL") },
];

export const ALL_CHAMPIONS_OPTIONS = [
  { value: "true", label: "true" },
  { value: "false", label: "false" },
];

export const TOGGLE_OPTIONS = [
  { value: "true", label: "true" },
  { value: "false", label: "false" },
];
