import { window } from "global";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";

import Modal from "components/Modal";
import { getBlockImg } from "@ugg/shared/utils/region-helpers";
import { ReactComponent as TriangleArrowDown } from "svg/triangle-arrow-down.svg";

const Region = (props) => {
  const { region, regionToApply, currentSetRegion, disabled, isMasthead, size, forceMastheadRegion } = props;

  if (!region) {
    return null;
  }

  const selected = regionToApply ? region === regionToApply : region === currentSetRegion;
  const regionImage = `${getBlockImg(region, isMasthead || forceMastheadRegion)}`;

  return (
    <div className={classnames("region-selector-item", { "region-selector-item_disabled": disabled })}>
      <img
        className={classnames({ selected })}
        src={regionImage}
        style={size === "large" ? { height: "40px", width: "40px" } : { height: "17px", width: "27px" }}
        alt="Region Image"
      />
    </div>
  );
};

const RegionSelector = (props) => {
  const { onApply, currentRegion, isMasthead, forceMastheadRegion, disableTooltip } = props;

  const inputTimeout = useRef();
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState("");
  const [clientRegion, setClientRegion] = useGlobal("clientRegion");
  const [init, setInit] = useState(false);
  const [regionToApply, setRegionToApply] = useState(currentRegion || clientRegion);
  const [modalOpen, setModalOpen] = useState(false);

  const availableRegions = [
    { value: "na1", label: "North America", shorthandLabel: "NA" },
    { value: "euw1", label: "Europe West", shorthandLabel: "EUW" },
    { value: "eun1", label: "Europe Nordic & East", shorthandLabel: "EUN" },
    { value: "kr", label: "Korea", shorthandLabel: "KR" },
    { value: "br1", label: "Brazil", shorthandLabel: "BR" },
    { value: "jp1", label: "Japan", shorthandLabel: "JP" },
    { value: "ru", label: "Russia", shorthandLabel: "RU" },
    { value: "oc1", label: "Oceania", shorthandLabel: "OCE" },
    { value: "tr1", label: "Turkey", shorthandLabel: "TR" },
    { value: "la1", label: "Latin America North", shorthandLabel: "LAN" },
    { value: "la2", label: "Latin America South", shorthandLabel: "LAS" },
    { value: "sg2", label: "Southeast Asia", shorthandLabel: "SEA" },
    { value: "tw2", label: "Taiwan", shorthandLabel: "TW" },
    { value: "vn2", label: "Vietnam", shorthandLabel: "VN" },
    { value: "me1", label: "Middle East", shorthandLabel: "ME" },
  ];

  const onChange = (e) => {
    setInputValue(e.target.value);
  };

  const onCancelHook = () => {
    setRegionToApply(null);
  };

  const setRegion = (region) => {
    if (onApply && region && region !== currentRegion) {
      onApply(region);
    }
    // Else, set universal region
    else if (region && region !== clientRegion) {
      localStorage.setItem("summonerRegion", region);
      setClientRegion(region);
    }
    setInputValue("");
    setRegionToApply(region);
    toggleModalOpen(false);
  };

  const toggleModalOpen = (bForceState) => {
    const toggleState = bForceState === undefined ? !modalOpen : bForceState;
    setModalOpen(toggleState);
  };

  useEffect(() => {
    setInit(true);
  }, []);

  useEffect(() => {
    if (inputRef.current && modalOpen) {
      inputRef.current.focus();
    }
  }, [modalOpen]);

  useEffect(() => {
    if (inputTimeout.current) {
      clearTimeout(inputTimeout.current);
    }
    inputTimeout.current = setTimeout(() => {
      setInputValue("");
    }, 1000);

    if (inputValue.trim() !== "") {
      for (const region of availableRegions) {
        if (
          region.shorthandLabel.toLowerCase().indexOf(inputValue.trim().toLowerCase()) === 0 ||
          region.label.toLowerCase().indexOf(inputValue.trim().toLowerCase()) === 0
        ) {
          return setRegionToApply(region.value);
        }
      }
    }
  }, [inputValue]);

  const handleEnterPress = useCallback(
    (e) => {
      if (e.which === 13) {
        // on enter
        setRegion(regionToApply);
      }
    },
    [regionToApply],
  );

  useEffect(() => {
    if (regionToApply && inputRef.current) {
      inputRef.current?.addEventListener("keypress", handleEnterPress);

      return () => inputRef.current?.removeEventListener("keypress", handleEnterPress);
    }
  }, [regionToApply, handleEnterPress]);

  if (!init) {
    return null;
  }

  let button = (
    <div className="flex-center">
      <Region
        region={currentRegion || clientRegion}
        isMasthead={isMasthead}
        size="large"
        forceMastheadRegion={forceMastheadRegion}
      />
      <div className="arrow-icon">
        <TriangleArrowDown />
      </div>
      <input className="region-selector-input" ref={inputRef} type="text" value={inputValue} onChange={onChange} />
    </div>
  );

  return (
    <Modal
      {...props}
      dataTip={!disableTooltip && "Summoner Region"}
      button={button}
      subtext="This will only affect summoner searches"
      modalClassNames={"region-selector-modal"}
      buttonClassName={"region-selector-button"}
      onCancel={onCancelHook}
      bDisableFooter
      toggleOpen={toggleModalOpen}
      bModalOpen={modalOpen}
    >
      <div className="region-selector-container">
        <div className="region-selector-list">
          {availableRegions.map((region, index) => (
            <div
              key={region.value}
              className={classnames("region-entry", { region_selected: regionToApply === region.value })}
              onClick={() => setRegion(region.value)}
              onMouseEnter={() => setRegionToApply(region.value)}
            >
              <Region region={region.value} regionToApply={regionToApply} currentSetRegion={regionToApply} />
              <div className={regionToApply === region.value ? "region-text-selected" : "region-text"}>{region.label}</div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default RegionSelector;
